// import { RepositoryFactory } from "../repositories/RepositoryFactory";
// const usersRepository = RepositoryFactory.get("users");
import { createRouter, createWebHashHistory } from "vue-router";

// import DashboardLayout from "@/layout/DashboardLayout";
// import AuthLayout from "@/layout/AuthLayout";
const AuthLayout = () => import("@/layout/AuthLayout");
// import Dashboard from "../views/Dashboard.vue";
const Dashboard = () => import("../views/Dashboard.vue");

// import Icons from "../views/Icons.vue";
const Icons = () => import("../views/Icons.vue");

// import Maps from "../views/Maps.vue";.
const Maps = () => import("../views/Maps.vue");

// import Profile from "../views/UserProfile.vue";
const Profile = () => import("../views/UserProfile.vue");

// import Tables from "../views/Tables.vue";
const Tables = () => import("../views/Tables.vue");

// import Login from "../views/Login.vue";
const Login = () => import("../views/Login.vue");

// import Register from "../views/Register.vue";
const Register = () => import("../views/Register.vue");

// import AddMember from "../views/Member/AddMember.vue";
const AddMember = () => import("../views/Member/AddMember.vue");

// import AddMemberTable from "../views/Member/AddMemberTable.vue";
const AddMemberTable = () => import("../views/Member/AddMemberTable.vue");

// import Charges from "../views/ManageCharges/Charges/Charges.vue";
const Charges = () => import("../views/ManageCharges/Charges/Charges.vue");

// import ChargesTable from "../views/ManageCharges/Charges/ChargesTable.vue";
const ChargesTable = () =>
  import("../views/ManageCharges/Charges/ChargesTable.vue");

// import ItemAution from "../views/ManageCharges/ItemAuction/ItemAuction";
const ItemAution = () =>
  import("../views/ManageCharges/ItemAuction/ItemAuction");

// import ItemAutionTable from "../views/ManageCharges/ItemAuction/ItemAuctionTable.vue";
const ItemAutionTable = () =>
  import("../views/ManageCharges/ItemAuction/ItemAuctionTable.vue");

// import Membership from "../views/ManageCharges/Membership/Membership.vue";
const Membership = () =>
  import("../views/ManageCharges/Membership/Membership.vue");

// import MembershipTable from "../views/ManageCharges/Membership/MembershipTable.vue";
const MembershipTable = () =>
  import("../views/ManageCharges/Membership/MembershipTable.vue");

// import Subscription from "../views/ManageCharges/Subscription/Subscription.vue";
const Subscription = () =>
  import("../views/ManageCharges/Subscription/Subscription.vue");

// import SubscriptionTable from "../views/ManageCharges/Subscription/SubscriptionTable.vue";
const SubscriptionTable = () =>
  import("../views/ManageCharges/Subscription/SubscriptionTable.vue");

// import ManageRoom from "../views/ManageRoom/ManageRoom.vue";
const ManageRoom = () => import("../views/ManageRoom/ManageRoom.vue");

// import ManageRoomTable from "../views/ManageRoom/ManageRoomTable.vue";
const ManageRoomTable = () => import("../views/ManageRoom/ManageRoomTable.vue");

// import Payment from "../views/Payment/Payment.vue";
const Payment = () => import("../views/Payment/Payment.vue");

// import PaymentTable from "../views/Payment/PaymentTable.vue";
const PaymentTable = () => import("../views/Payment/PaymentTable.vue");

// import Auction from "../views/Auction/Auction.vue";

const Auction = () => import("../views/Auction/Auction.vue");

// import AuctionTemplate from "../views/Auction/AuctionTemplate.vue";
const AuctionTemplate = () => import("../views/Auction/AuctionTemplate.vue");

// import AuctionTemplateTable from "../views/Auction/AuctionTemplateTable.vue";
const AuctionTemplateTable = () =>
  import("../views/Auction/AuctionTemplateTable.vue");

// import AuctionTable from "../views/Auction/AuctionTable.vue";
const AuctionTable = () => import("../views/Auction/AuctionTable.vue");

// import Account from "../views/Account/Account.vue";
const Account = () => import("../views/Account/Account.vue");

// import AccountTable from "../views/Account/AccountTable.vue";
const AccountTable = () => import("../views/Account/AccountTable.vue");

// import BulkCharge from "../views/BulkCharge/BulkCharge.vue";
const BulkCharge = () => import("../views/BulkCharge/BulkCharge.vue");

// import BulkChargeTable from "../views/BulkCharge/BulkChargeTable.vue";
const BulkChargeTable = () => import("../views/BulkCharge/BulkChargeTable.vue");

// import Receipt from "../views/Receipt/Receipt.vue";
const Receipt = () => import("../views/Receipt/Receipt.vue");

// import ReceiptTable from "../views/Receipt/ReceiptTable.vue";
const ReceiptTable = () => import("../views/Receipt/ReceiptTable.vue");

// import CreateMemberGroup from "../views/MemberGroup/createMemberGroup.vue";
const CreateMemberGroup = () =>
  import("../views/MemberGroup/createMemberGroup.vue");

// import MemberGroupTable from "../views/MemberGroup/memberGroupTable.vue";
const MemberGroupTable = () =>
  import("../views/MemberGroup/memberGroupTable.vue");

// import user from "../views/User/user.vue";
const user = () => import("../views/User/user.vue");

// import userTable from "../views/User/userTable.vue";
const userTable = () => import("../views/User/userTable.vue");

// import SeatManagement from "../views/SeatManagement/CreateSeatManagement.vue";
const SeatManagement = () =>
  import("../views/SeatManagement/CreateSeatManagement.vue");

// import SeatManagementTable from "../views/SeatManagement/SeatManagementTable";
const SeatManagementTable = () =>
  import("../views/SeatManagement/SeatManagementTable");

// import Calender from "../views/Calender/Calender.vue";
const Calender = () => import("../views/Calender/Calender.vue");

// import Info from "../views/Info/Info.vue";
// import Report from "../views/Report/Report.vue";
const Report = () => import("../views/Report/Report.vue");

// import IncomeReport from "../views/Report/IncomeReport.vue";
const IncomeReport = () => import("../views/Report/IncomeReport.vue");

// import EmailTemplate from "../views/EmailTemplate/EmailTemplate.vue";
const EmailTemplate = () => import("../views/EmailTemplate/EmailTemplate.vue");

// import AddCharge from "../views/Account/AddCharge.vue";
const AddCharge = () => import("../views/Account/AddCharge.vue");
// import PrintReciept from "../views/Setting/PrintReciept.vue";
const PrintReciept = () => import("../views/Setting/PrintReciept.vue");

// import Statement from "../views/Setting/Statement.vue";
const Statement = () => import("../views/Setting/Statement.vue");

const monthsReport = () => import("../views/Report/monthsReport.vue");
const IncomeMemberReport = () =>
  import("../views/Report/IncomeMemberReport.vue");
const chargeMemberReport = () =>
  import("../views/Report/chargeMemberReport.vue");

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/DashboardLayout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: {
          default: Dashboard,
        },
      },
      {
        path: "/icons",
        name: "icons",
        components: {
          default: Icons,
        },
      },
      {
        path: "/maps",
        name: "maps",
        components: {
          default: Maps,
        },
      },
      {
        path: "/profile",
        name: "profile",
        components: {
          default: Profile,
        },
      },
      {
        path: "/tables",
        name: "tables",
        components: {
          default: Tables,
        },
      },
      {
        path: "/member/member/:data",
        name: "createmember",
        components: {
          default: AddMember,
        },
      },
      {
        path: "/member/viewmember",
        name: "member",
        components: {
          default: AddMemberTable,
        },
      },
      {
        path: "/manageCharges/Charges/:data",
        name: "createCharges",
        components: {
          default: Charges,
        },
      },

      {
        path: "/manageCharges/view_Charges",
        name: "charges",
        components: {
          default: ChargesTable,
        },
      },
      {
        path: "/manageCharges/ItemAction/:data",
        name: "ItemAution",
        components: {
          default: ItemAution,
        },
      },
      {
        path: "/manageCharges/view_Itemauction",
        name: "itemauction",
        components: {
          default: ItemAutionTable,
        },
      },
      {
        path: "/manageCharges/create_Membership/:data",
        name: "createMembership",
        components: {
          default: Membership,
        },
      },
      {
        path: "/manageCharges/view_Membership",
        name: "Membership",
        components: {
          default: MembershipTable,
        },
      },
      {
        path: "/manageCharges/Subscription/:data",
        name: "createSubscription",
        components: {
          default: Subscription,
        },
      },
      {
        path: "/manageCharges/view_subscription",
        name: "Subscription",
        components: {
          default: SubscriptionTable,
        },
      },
      {
        path: "/manageroom/add_manageroom/:data",
        name: "CreateRoom",
        components: {
          default: ManageRoom,
        },
      },
      {
        path: "/manageroom/view_manageroom",
        name: "manageroom",
        components: {
          default: ManageRoomTable,
        },
      },
      {
        path: "/payment/create_payment/:data",
        name: "createpayment",
        components: {
          default: Payment,
        },
      },
      {
        path: "/payment/view_payment",
        name: "payment",
        components: {
          default: PaymentTable,
        },
      },
      {
        path: "/auction/create_auction/:data",
        name: "createauction",
        components: {
          default: Auction,
        },
      },
      {
        path: "/auction/create_auction_template/:data",
        name: "createauctiontemplate",
        components: {
          default: AuctionTemplate,
        },
      },
      {
        path: "/auction/view_auction_template",
        name: "viewuctiontemptable",
        components: {
          default: AuctionTemplateTable,
        },
      },
      {
        path: "/auction/view_auction",
        name: "auction",
        components: {
          default: AuctionTable,
        },
      },
      {
        path: "/account/account_details",
        name: "create account",
        components: {
          default: Account,
        },
      },
      {
        path: "/account/view_account",
        name: "account",
        components: {
          default: AccountTable,
        },
      },
      {
        path: "/account/add_charge",
        name: "addcharge",
        components: {
          default: AddCharge,
        },
      },
      {
        path: "/bulkcharge/bulk_charge/:data",
        name: "createbulkcharge",
        components: {
          default: BulkCharge,
        },
      },
      {
        path: "/bulkcharge/view_bulkcharge",
        name: "bulkcharge",
        components: {
          default: BulkChargeTable,
        },
      },
      {
        path: "/reciept/create_reciept/:data",
        name: "createreciept",
        components: {
          default: Receipt,
        },
      },
      {
        path: "/reciept/view_reciept",
        name: "reciept",
        components: {
          default: ReceiptTable,
        },
      },
      {
        path: "/membergroup/member_group/:data",
        name: "createmembergroup",
        components: {
          default: CreateMemberGroup,
        },
      },
      {
        path: "/memberGroup/view_member_group",
        name: "Memeber Group",
        components: {
          default: MemberGroupTable,
        },
      },

      {
        path: "/seatManagement/create_seatmanagement",
        name: " create seatmanagement",
        components: {
          default: SeatManagement,
        },
      },

      {
        path: "/seatManagement/view_seatmanagement",
        name: " seatmanagement",
        components: {
          default: SeatManagementTable,
        },
      },
      {
        path: "/user/create_user/:data",
        name: "createuser",
        components: {
          default: user,
        },
      },
      {
        path: "/user/view_user",
        name: "user",
        components: {
          default: userTable,
        },
      },
      {
        path: "/calender/view_calender",
        name: "Calender",
        components: {
          default: Calender,
        },
      },
      {
        path: "/report/charge_report",
        name: " Charge Report",
        components: {
          default: Report,
        },
      },
      {
        path: "/report/income_report",
        name: " Income Report",
        components: {
          default: IncomeReport,
        },
      },
      {
        path: "/report/member_income_report",
        name: " Member Income Report",
        components: {
          default: IncomeMemberReport,
        },
      },

      {
        path: "/report/member_charge_report",
        name: " Member Charge Report",
        components: {
          default: chargeMemberReport,
        },
      },

      {
        path: "/report/month_report",
        name: "30,60,90 Report",
        components: {
          default: monthsReport,
        },
      },

      // {
      //   path: "/info/view_info",
      //   name: "Info",
      //   components: {
      //     default: Info,
      //   },
      // },
      {
        path: "/emailtemplate/view_emailtemplate",
        name: "Email Template",
        components: {
          default: EmailTemplate,
        },
      },
      {
        path: "/setting/print_reciept",
        name: "printreciept",
        components: {
          default: PrintReciept,
        },
      },
      {
        path: "/setting/statement",
        name: "statement",
        components: {
          default: Statement,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: {
          default: Login,
        },
      },
      {
        path: "/register",
        name: "register",
        components: {
          default: Register,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to, from, next) => {
  let user = false;
  let localuser = "";
  if (localStorage.getItem("user")) {
    localuser = localStorage.getItem("user");
    if (localuser) {
      // const user_date = await usersRepository.getUser(JSON.parse(localuser).id);
      // eslint-disable-next-line no-empty
      // if (user_date.data) {
      // if (user_date.data[0].id == JSON.parse(localuser).id) {
      user = true;
      // }
      // }
    }
  }
  if (to.name !== "login" && !user)
    next({
      name: "login",
    });
  else next();
});

export default router;
