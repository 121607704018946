<template>
  <div id="app">
    <router-view></router-view>
    <loader :is-visible="isLoading"></loader>
  </div>
</template>

<script>
import Repository from "./repositories/Repository.js";
import Loader from "./global/Loader.vue";

export default {
  components: {
    Loader,
  },
  mounted() {
    this.enableInterceptor();
  },
  data() {
    return {
      isLoading: false,
      axiosInterceptor: null,
    };
  },
  methods: {
    enableInterceptor() {
      const comp = this;
      this.axiosInterceptor = Repository.interceptors.request.use(
        (config) => {
          comp.isLoading = true;
          return config;
        },
        (error) => {
          comp.isLoading = false;
          return Promise.reject(error);
        }
      );

      Repository.interceptors.response.use(
        (response) => {
          comp.isLoading = false;
          return response;
        },
        function (error) {
          comp.isLoading = false;
          return Promise.reject(error);
        }
      );
    },

    disableInterceptor() {
      Repository.interceptors.request.eject(this.axiosInterceptor);
    },
  },
};
</script>
