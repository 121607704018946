import axios from "axios";

// You can use your own logic to set your local or production domain

let baseDomain = "";

if (
  window.webpackHotUpdate ||
  (process.env.NODE_ENV !== "production" &&
    process.env.NODE_ENV !== "test" &&
    typeof console !== "undefined")
) {
  baseDomain = "https://crm.saptal.in/api/index.php";
} else {
  baseDomain = "https://shulsoftware.adass.com.au/api/index.php";
}
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;

const httpClient = axios.create({
  baseURL: baseURL,
  timeout: 5000,
});

// const getAuthToken = () => localStorage.getItem("token");

const authInterceptor = (config) => {
  // config.headers["Authorization"] = "Bearer " + getAuthToken();
  config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  return config;
};

httpClient.interceptors.request.use(authInterceptor);

export default httpClient;
